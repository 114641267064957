import { Dispatch, FC } from 'react';
import { Box, TextField } from '@mui/material';

import { NumberInput, Textarea } from 'src/components/ui';

import { useStyles } from './styles';
import {
  PerformanceUpdate,
  InvestorProfile,
  KeyInformation,
  PortfolioAim,
  PortfolioCharacteristics,
  PortfolioUpdate,
  SateliteTable,
  Timestamp,
} from '../assets';
import { FactsheetSection } from '../FactsheetSection';
import { State } from '../interfaces';

const maxPerformanceUpdateLength = 150;
const maxInceptionDateLength = 'dd September yyyy'.length;
const maxAvailableShareClassLength = 50;
const maxInvestorProfileLength = 380;
const maxPortfolioAimLength = 380;
const maxPortfolioAimFinePrintLength = 180;
const maxPortfolioCharacteristicsLength = 380;
const maxPortfolioUpdateLength = 2050;
const maxDisclaimerLength = 300;

interface Props {
  values: State;
  dispatch: Dispatch<Partial<State>>;
}

export const FirstPage: FC<Props> = ({ values, dispatch }) => {
  const { classes } = useStyles();

  let isInvestorProfileInvalid = false;
  let isPortfolioAimInvalid = false;
  let isPortfolioAimFinePrintInvalid = false;
  let isPortfolioCharacteristicsInvalid = false;
  let isPortfolioUpdateInvalid = false;
  let isInceptionDateInvalid = false;
  let isAvailableShareClassInvalid = false;
  let isPerformanceUpdateBodyInvalid = false;
  let isDisclaimerInvalid = false;

  if (values?.keyInformation?.inceptionDate) {
    isInceptionDateInvalid =
      values?.keyInformation?.inceptionDate?.length > maxInceptionDateLength;
  }
  if (values?.keyInformation?.availableShareClass) {
    isAvailableShareClassInvalid =
      values?.keyInformation?.availableShareClass?.length >
      maxAvailableShareClassLength;
  }
  if (values.investorProfile) {
    isInvestorProfileInvalid =
      values.investorProfile.length > maxInvestorProfileLength;
  }
  if (values.portfolioAim) {
    isPortfolioAimInvalid = values.portfolioAim.length > maxPortfolioAimLength;
  }
  if (values.portfolioAimFinePrint) {
    isPortfolioAimFinePrintInvalid =
      values.portfolioAimFinePrint.length > maxPortfolioAimFinePrintLength;
  }
  if (values.portfolioCharacteristics) {
    isPortfolioCharacteristicsInvalid =
      values.portfolioCharacteristics.length >
      maxPortfolioCharacteristicsLength;
  }
  if (values.portfolioUpdate) {
    isPortfolioUpdateInvalid =
      values.portfolioUpdate.length > maxPortfolioUpdateLength;
  }
  if (values.performanceUpdate?.body) {
    isPerformanceUpdateBodyInvalid =
      values.performanceUpdate.body.length > maxPerformanceUpdateLength;
  }

  if (values.portfolioChargesDisclaimer) {
    isDisclaimerInvalid =
      values.portfolioChargesDisclaimer.length > maxDisclaimerLength;
  }

  return (
    <form className={classes.factsheetForm}>
      <Box style={{ display: 'inline-flex' }}>
        <FactsheetSection label="Key Information" image={<KeyInformation />}>
          <NumberInput
            className={classes.inputNumber}
            label="Historic Yield"
            value={values.keyInformation?.historicYield ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  historicYield: value,
                },
              })
            }
            percentFormat
          />
          <TextField
            className={classes.textField}
            label="Number of Holdings"
            name="numberOfHoldings"
            autoComplete="off"
            variant="filled"
            style={{ width: 240 }}
            fullWidth
            value={values.keyInformation?.numberOfHoldings ?? ''}
            onChange={(ev) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  numberOfHoldings: ev.target.value,
                },
              })
            }
          />
          <NumberInput
            className={classes.inputNumber}
            label="Growth Assets"
            value={values.keyInformation?.growAssets ?? 0 ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  growAssets: value,
                },
              })
            }
            percentFormat
          />
          <TextField
            className={classes.textField}
            autoComplete="off"
            variant="filled"
            name="minimumInvestment"
            value={values.keyInformation?.minimumInvestment ?? ''}
            label="Minimum Investment"
            style={{ width: 240 }}
            fullWidth
            onChange={(ev) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  minimumInvestment: ev.target.value,
                },
              })
            }
          />
          <TextField
            className={classes.textField}
            autoComplete="off"
            variant="filled"
            name="inceptionDate"
            value={values.keyInformation?.inceptionDate ?? ''}
            label="Inception Date"
            style={{ width: 240 }}
            fullWidth
            onChange={(ev) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  inceptionDate: ev.target.value,
                },
              })
            }
            error={isInceptionDateInvalid}
            helperText={isInceptionDateInvalid ? 'Too many characters.' : ''}
          />
          <TextField
            className={classes.textField}
            autoComplete="off"
            variant="filled"
            name="availableShareClass"
            value={values.keyInformation?.availableShareClass ?? ''}
            label="Available Share Class"
            style={{ width: 240 }}
            fullWidth
            onChange={(ev) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  availableShareClass: ev.target.value,
                },
              })
            }
            error={isAvailableShareClassInvalid}
            helperText={
              isAvailableShareClassInvalid ? 'Too many characters.' : ''
            }
          />
          <Textarea
            name="portfolioChargesDisclaimer"
            value={values.portfolioChargesDisclaimer ?? ''}
            label="Disclaimer"
            rows={4}
            width="200%"
            maxCharLength={maxDisclaimerLength}
            charLimit={maxDisclaimerLength}
            tip="4 lines"
            onChange={(ev) =>
              dispatch({ portfolioChargesDisclaimer: ev.target.value })
            }
            error={isDisclaimerInvalid}
            helperText={isDisclaimerInvalid ? 'Too many characters.' : ''}
          />
        </FactsheetSection>
        <FactsheetSection label="Portfolio Charges" image={<></>}>
          <TextField
            className={classes.textField}
            autoComplete="off"
            variant="filled"
            label="Portfolio Management Fee"
            name="portfolioManagementFee"
            style={{ width: 240 }}
            fullWidth
            value={values.keyInformation?.portfolioManagementFee ?? ''}
            inputProps={{ maxLength: 20 }}
            onChange={(ev) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  portfolioManagementFee: ev.target.value,
                },
              })
            }
          />
          <NumberInput
            className={classes.inputNumber}
            label="Ongoing Cost of Funds"
            value={values.keyInformation?.ongoingCostOfFunds ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  ongoingCostOfFunds: value,
                },
              })
            }
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="Total Portfolio Cost"
            value={values.keyInformation?.totalPortfolioCost ?? ''}
            charLimit={10}
            onChange={(value) => {
              dispatch({
                keyInformation: {
                  ...values.keyInformation,
                  totalPortfolioCost: value,
                },
              });
            }}
            percentFormat
            allowEmpty
          />
        </FactsheetSection>
      </Box>
      <FactsheetSection image={<InvestorProfile />}>
        <Textarea
          name="investorProfile"
          value={values.investorProfile ?? ''}
          label="Investor Profile"
          rows={10}
          maxCharLength={maxInvestorProfileLength}
          charLimit={maxInvestorProfileLength}
          tip="10 lines"
          onChange={(ev) => dispatch({ investorProfile: ev.target.value })}
          error={isInvestorProfileInvalid}
          helperText={isInvestorProfileInvalid ? 'Too many characters.' : ''}
        />
      </FactsheetSection>
      <FactsheetSection image={<PortfolioAim />}>
        <Textarea
          name="portfolioAim"
          value={values.portfolioAim ?? ''}
          label="Portfolio Aim"
          rows={10}
          maxCharLength={maxPortfolioAimLength}
          charLimit={maxPortfolioAimLength}
          tip="10 lines"
          onChange={(ev) => dispatch({ portfolioAim: ev.target.value })}
          error={isPortfolioAimInvalid}
          helperText={isPortfolioAimInvalid ? 'Too many characters.' : ''}
        />
        <Box style={{ marginTop: 20 }} />
        <Textarea
          name="portfolioAimFinePrint"
          value={values.portfolioAimFinePrint ?? ''}
          label="Fine print"
          rows={5}
          maxCharLength={maxPortfolioAimFinePrintLength}
          charLimit={maxPortfolioAimFinePrintLength}
          tip="5 lines"
          onChange={(ev) =>
            dispatch({ portfolioAimFinePrint: ev.target.value })
          }
          error={isPortfolioAimFinePrintInvalid}
          helperText={
            isPortfolioAimFinePrintInvalid ? 'Too many characters.' : ''
          }
        />
      </FactsheetSection>
      <FactsheetSection image={<PortfolioCharacteristics />}>
        <Textarea
          name="portfolioCharacteristics"
          value={values.portfolioCharacteristics ?? ''}
          label="Portfolio Characteristics"
          rows={8}
          maxCharLength={maxPortfolioCharacteristicsLength}
          charLimit={maxPortfolioCharacteristicsLength}
          tip="5 lines"
          onChange={(ev) =>
            dispatch({ portfolioCharacteristics: ev.target.value })
          }
          error={isPortfolioCharacteristicsInvalid}
          helperText={
            isPortfolioCharacteristicsInvalid ? 'Too many characters.' : ''
          }
        />
      </FactsheetSection>

      <FactsheetSection image={<PerformanceUpdate />}>
        <Textarea
          name="performanceUpdate"
          value={values?.performanceUpdate?.body ?? ''}
          label="Performance Update"
          rows={1}
          multiline={false}
          onChange={(ev) =>
            dispatch({
              performanceUpdate: {
                ...values.performanceUpdate,
                body: ev.target.value,
              },
            })
          }
          error={isPerformanceUpdateBodyInvalid}
          helperText={
            isPerformanceUpdateBodyInvalid ? 'Too many characters.' : ''
          }
          charLimit={maxPerformanceUpdateLength}
          maxCharLength={maxPerformanceUpdateLength}
          tip="1 line"
        />
      </FactsheetSection>

      <Box style={{ display: 'inline-flex' }}>
        <FactsheetSection label="First row" image={<SateliteTable />}>
          <TextField
            className={classes.inputNumber}
            autoComplete="off"
            variant="filled"
            name="topTableLabel"
            value={values.performanceUpdate?.topTableLabel ?? ''}
            label="Label"
            onChange={(ev) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  topTableLabel: ev.target.value,
                },
              })
            }
            fullWidth
          />
          <NumberInput
            className={classes.inputNumber}
            label="1m"
            value={values.performanceUpdate?.top1m ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  top1m: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="3m"
            value={values.performanceUpdate?.top3m ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  top3m: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="6m"
            value={values.performanceUpdate?.top6m ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  top6m: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="1y"
            value={values.performanceUpdate?.top1y ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  top1y: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="3y"
            value={values.performanceUpdate?.top3y ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  top3y: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="5y"
            value={values.performanceUpdate?.top5y ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  top5y: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="YTD"
            value={values.performanceUpdate?.topInception ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  topInception: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
        </FactsheetSection>
        <FactsheetSection label="Second row" image={<></>}>
          <TextField
            className={classes.inputNumber}
            autoComplete="off"
            variant="filled"
            name="bottomTableLabel"
            value={values.performanceUpdate?.bottomTableLabel ?? ''}
            label="Label"
            onChange={(ev) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottomTableLabel: ev.target.value,
                },
              })
            }
            fullWidth
          />
          <NumberInput
            className={classes.inputNumber}
            label="1m"
            value={values.performanceUpdate?.bottom1m ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottom1m: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="3m"
            value={values.performanceUpdate?.bottom3m ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottom3m: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="6m"
            value={values.performanceUpdate?.bottom6m ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottom6m: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="1y"
            value={values.performanceUpdate?.bottom1y ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottom1y: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="3y"
            value={values.performanceUpdate?.bottom3y ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottom3y: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="5y"
            value={values.performanceUpdate?.bottom5y ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottom5y: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
          <NumberInput
            className={classes.inputNumber}
            label="YTD"
            value={values.performanceUpdate?.bottomInception ?? ''}
            charLimit={10}
            onChange={(value) =>
              dispatch({
                performanceUpdate: {
                  ...values.performanceUpdate,
                  bottomInception: value,
                },
              })
            }
            precision={2}
            percentFormat
          />
        </FactsheetSection>
      </Box>

      <FactsheetSection image={<PortfolioUpdate />}>
        <Textarea
          name="portfolioUpdate"
          value={values.portfolioUpdate ?? ''}
          label="Portfolio Update"
          rows={15}
          maxCharLength={maxPortfolioUpdateLength}
          charLimit={maxPortfolioUpdateLength}
          tip="15 lines"
          onChange={(ev) => dispatch({ portfolioUpdate: ev.target.value })}
          error={isPortfolioUpdateInvalid}
          helperText={isPortfolioUpdateInvalid ? 'Too many characters.' : ''}
        />
      </FactsheetSection>

      <FactsheetSection label="Timestamp" image={<Timestamp />}>
        <TextField
          className={classes.textField}
          autoComplete="off"
          variant="filled"
          name="topLine"
          value={values.timeStamp?.topLine ?? ''}
          label="Top line"
          onChange={(ev) =>
            dispatch({
              timeStamp: {
                ...values.timeStamp,
                topLine: ev.target.value,
              },
            })
          }
          fullWidth
        />
        <TextField
          className={classes.textField}
          autoComplete="off"
          variant="filled"
          name="bottomLine"
          value={values.timeStamp?.bottomLine ?? ''}
          label="Bottom line"
          onChange={(ev) =>
            dispatch({
              timeStamp: {
                ...values.timeStamp,
                bottomLine: ev.target.value,
              },
            })
          }
          fullWidth
        />
      </FactsheetSection>
    </form>
  );
};
